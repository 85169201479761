import { RawLinkButton } from '@/components/Button';
import env from '@/util/env';

const { BASE_URL } = env;

type Props = {
  type?: 'login' | 'register';
};

export function SsoLoginButtonList({ type = 'login' }: Props) {
  const prefix = type === 'login' ? 'Inloggen met' : 'Registreren via';

  return (
    <div
      // Fixme
      style={{
        display: 'grid',
        gap: 16,
        justifyContent: 'center',
        gridAutoColumns: 'max-content',
      }}
    >
      <RawLinkButton
        align="center"
        icon="google"
        theme="secondary"
        href={`${BASE_URL}/account/login/google`}
        target="_self"
      >
        {prefix} Google
      </RawLinkButton>

      <RawLinkButton
        align="center"
        icon="facebook"
        theme="secondary"
        href={`${BASE_URL}/account/login/facebook`}
        target="_self"
      >
        {prefix} Facebook
      </RawLinkButton>
    </div>
  );
}
