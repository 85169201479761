import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Flex } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { Input } from '@/components/Input';
import { PostalCodeField } from '@/components/PostalCodeField';
import { LocationItemType } from '@/resource/location';
import { SetState } from '@/util/types';

type Props = {
  isValid: boolean;
  phone: string;
  setPhone: SetState<string>;
  postalCode: string;
  setPostalCode: SetState<string>;
  location: LocationItemType | null;
  setLocation: SetState<LocationItemType | null>;
  allowContact: boolean;
  setAllowContact: SetState<boolean>;
  onBack: () => void;
  onNext: () => void;
};

export function Step2({
  phone,
  setPhone,
  postalCode,
  setPostalCode,
  location,
  setLocation,
  allowContact,
  setAllowContact,
  isValid,
  onBack,
  onNext,
}: Props) {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <Flex dir="column" gap={4}>
        <Heading align="center">Contact gegevens</Heading>

        <p>
          De gegevens die je invult worden vertrouwelijk door ons behandeld. We
          gebruiken ze alleen om je zo goed mogelijk te helpen en om Jouw NH
          veilig te houden.
        </p>

        <Input
          label="Telefoonnummer (optioneel)"
          type="tel"
          id="phone"
          value={phone}
          minLength={10}
          maxLength={10}
          placeholder="0612345678"
          autoComplete="tel"
          onChange={(e) => setPhone(e.target.value.replace(/[^\d+]/, ''))}
        />

        <PostalCodeField
          location={location}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          setLocation={setLocation}
        />

        <Flex align="center">
          <input
            type="checkbox"
            name="allow_contact"
            id="allow_contact"
            checked={allowContact}
            onChange={(e) => setAllowContact(e.target.checked)}
          />
          <label htmlFor="allow_contact">
            Je mag contact met me opnemen voor radio/tv
          </label>
        </Flex>

        <Flex justify="space-between">
          <Button
            theme="secondary"
            onClick={onBack}
            icon="chevron_left_narrow"
            iconSize={2}
          >
            Naam
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            icon="chevron_right_narrow"
            iconSize={2}
            iconPosition="right"
          >
            Profielfoto
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
}
