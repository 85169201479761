import { Link } from '@/components/Link';
import { Text } from '@/components/Text';
import { Wizard } from '@/components/Wizard';
import { useModal } from '@/hooks/useModal';
import { getAccount, updateProfile } from '@/resource/account';
import { uploadAvatar } from '@/resource/ad';
import { LocationItemType } from '@/resource/location';
import { PrivateProfileItemType } from '@/resource/profile';
import { routeToPath } from '@/util/routeToPath';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { PageRefType } from '@/resource/settings';
import z from 'zod';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';

const avatarVariations = Array.from({ length: 8 }, (_, i) => i + 1);
const randomVariation = () =>
  Math.ceil(Math.random() * avatarVariations.length);

const step1 = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

const step2 = z.object({
  phone: z
    .string()
    .length(10)
    .regex(/^0[0-9]{9}$/)
    .or(z.literal('')),
  postalCode: z.string().length(4),
  allowContact: z.boolean(),
  location: z.object({
    id: z.number(),
  }),
});

const step3 = z.object({
  image:
    typeof Blob === 'function'
      ? z.instanceof(Blob).nullable().optional()
      : z.string().nullable().optional(), // ssr
});

const step4 = z.object({
  iconId: z.number().min(1).max(8),
  paletteColor: z.number().min(1).max(8),
});

type Props = {
  profile: PrivateProfileItemType;
  rulesPage: PageRefType | null;
};

export function CompleteProfile({ profile, rulesPage }: Props) {
  const router = useRouter();
  const modal = useModal();

  // View index.
  const [index, setIndex] = useState(0);

  // Profile fields.
  const locationData = profile.location?.data;
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name || '');
  const [phone, setPhone] = useState('');
  const [postalCode, setPostalCode] = useState(
    locationData ? String(locationData.postal_code) : ''
  );
  const [location, setLocation] = useState<LocationItemType | null>(null);
  const [allowContact, setAllowContact] = useState(true);

  // Update avatar icon and palette.
  const [paletteColor, setPaletteColor] = useState(1);
  const [iconId, setIconId] = useState(1);
  useEffect(() => {
    setPaletteColor(randomVariation());
  }, []);

  // Avatar
  const [image, setImage] = useState<Blob | null>(null);

  function finishProfile() {
    modal.open(
      {
        type: 'message',
        props: {
          title: 'Je profiel is klaar!',
          illustration: 'hart',
          description: (
            <Text balance align="center">
              Volg alsjeblieft{' '}
              <Link
                target="_blank"
                underline
                to={
                  rulesPage
                    ? { type: 'info', page: rulesPage }
                    : { type: 'account' }
                }
              >
                onze regels
              </Link>{' '}
              om ons platform vriendelijk en veilig te houden
            </Text>
          ),
        },
      },
      () =>
        getAccount().then(() =>
          router.replace(routeToPath({ type: 'account' }))
        )
    );
  }

  return (
    <Wizard
      index={index}
      setIndex={setIndex}
      views={[
        {
          index: 0,
          title: 'Naam',
          isDisabled: () => false,
          isValid: () => step1.safeParse({ firstName, lastName }).success,
          render: ({ isValid }) => (
            <Step1
              isValid={isValid}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              onNext={() => setIndex(1)}
            />
          ),
        },

        {
          index: 1,
          title: 'Contact gegevens',
          isDisabled: () => false,
          isValid: () =>
            step2.safeParse({ phone, postalCode, allowContact, location })
              .success,
          render: ({ isValid }) => (
            <Step2
              isValid={isValid}
              phone={phone}
              setPhone={setPhone}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              location={location}
              setLocation={setLocation}
              allowContact={allowContact}
              setAllowContact={setAllowContact}
              onBack={() => setIndex(0)}
              onNext={() => {
                updateProfile({
                  firstName,
                  lastName,
                  allowContact,
                  locationId: location?.id || -1,
                  phone,
                }).then(() => setIndex(2));
              }}
            />
          ),
        },

        {
          index: 2,
          title: 'Profiel foto',
          isDisabled: () => false,
          isValid: () => step3.safeParse({ image }).success,
          render: ({ isValid }) => (
            <Step3
              isValid={isValid}
              image={image}
              setImage={setImage}
              onBack={() => setIndex(1)}
              onNext={() => {
                if (image) {
                  uploadAvatar({
                    image,
                    iconId,
                    paletteColor,
                    onUploadProgress() {
                      //
                    },
                  }).then((res) => {
                    if (res.type === 'ok') {
                      finishProfile();
                    }
                  });
                } else {
                  setIndex(3);
                }
              }}
            />
          ),
        },

        {
          index: 3,
          title: 'Kies een avatar',
          isDisabled: () => false,
          isValid: () => step4.safeParse({ iconId, paletteColor }).success,
          render: ({ isValid }) => (
            <Step4
              isValid={isValid}
              iconId={iconId}
              setIconId={setIconId}
              paletteColor={paletteColor}
              setPaletteColor={setPaletteColor}
              onBack={() => setIndex(2)}
              onNext={() => {
                uploadAvatar({
                  iconId,
                  paletteColor,
                  onUploadProgress() {
                    //
                  },
                }).then((res) => {
                  if (res.type === 'ok') {
                    finishProfile();
                  }
                });
              }}
            />
          ),
        },
      ]}
    />
  );
}
