import { IconButton } from '@/components/IconButton';
import { accept } from '@/util/image';
import { useRef } from 'react';
import styles from './AvatarUpload.module.scss';

type Props = {
  src: string | null | undefined;
  onChange: (file: File) => void;
};

export function AvatarUpload({ src, onChange }: Props) {
  const ref = useRef<HTMLLabelElement>(null);

  return (
    <div className={styles.AvatarUpload}>
      <div className={styles.Icon} />
      <div
        className={styles.Image}
        style={
          src
            ? {
                backgroundImage: `url(${src})`,
              }
            : undefined
        }
      />

      <label ref={ref}>
        <input
          type="file"
          accept={accept}
          onChange={({ target: { files } }) => {
            if (files?.length) {
              onChange(files[0]);
            }
          }}
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
        <span>Upload je profielfoto</span>
      </label>

      <IconButton
        icon="pen"
        title={src ? 'Foto aanpassen' : 'Foto selecteren'}
        onClick={() => ref.current?.click()}
      />
    </div>
  );
}
