import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Flex } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { Input } from '@/components/Input';
import { SetState } from '@/util/types';

type Props = {
  isValid: boolean;
  firstName: string;
  setFirstName: SetState<string>;
  lastName: string;
  setLastName: SetState<string>;
  onNext: () => void;
};

export function Step1({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  isValid,
  onNext,
}: Props) {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <Flex dir="column" gap={4}>
        <Heading align="center">Voltooi je profiel</Heading>

        <p>
          Leden gebruiken hun echte naam om een betrouwbare community te bouwen.
          Alleen je voornaam zal zichtbaar zijn op de website
        </p>

        <Input
          label="Voornaam"
          type="text"
          id="first_name"
          value={firstName}
          minLength={1}
          maxLength={128}
          required
          autoComplete="given-name"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          label="Achternaam"
          type="text"
          id="last_name"
          value={lastName}
          minLength={1}
          maxLength={128}
          required
          autoComplete="family-name"
          onChange={(e) => setLastName(e.target.value)}
        />

        <Flex justify="end">
          <Button
            type="submit"
            disabled={!isValid}
            icon="chevron_right_narrow"
            iconSize={2}
            iconPosition="right"
          >
            Contact gegevens
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
}
