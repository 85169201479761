export function scrollIntoViewWithOffset(
  el: HTMLElement,
  offset = 0,
  behavior: 'auto' | 'smooth' = 'auto'
) {
  window.scrollTo({
    behavior,
    top:
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top +
      offset,
  });
}
