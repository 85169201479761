import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Flex, GridAutoFill } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { IconAvatar } from '@/components/IconAvatar';
import { SetState } from '@/util/types';

type Props = {
  isValid: boolean;
  iconId: number;
  setIconId: SetState<number>;
  paletteColor: number;
  setPaletteColor: SetState<number>;
  onBack: () => void;
  onNext: () => void;
};

const avatarVariations = Array.from({ length: 8 }, (_, i) => i + 1);

export function Step4({
  isValid,
  iconId,
  paletteColor,
  setIconId,
  setPaletteColor,
  onBack,
  onNext,
}: Props) {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <Flex dir="column" gap={4}>
        <Heading align="center">Kies een avatar</Heading>

        <GridAutoFill minColumnWidth={{ sm: 140, md: 80 }} gap={2}>
          {avatarVariations.map((id) => (
            <IconAvatar
              fluid="width"
              key={id}
              icon={id}
              title={`Kies avatar icon ${id}`}
              selected={iconId === id}
              onClick={() => setIconId(id)}
            />
          ))}
        </GridAutoFill>

        <Heading level={3} align="center">
          Kies een kleur
        </Heading>

        <GridAutoFill minColumnWidth={{ sm: 140, md: 80 }} gap={2}>
          {avatarVariations.map((id) => (
            <IconAvatar
              fluid="width"
              key={id}
              palette={id}
              icon={iconId}
              title={`Kies avatar kleur ${id}`}
              selected={paletteColor === id}
              onClick={() => setPaletteColor(id)}
            />
          ))}
        </GridAutoFill>

        <Flex justify="space-between">
          <Button
            theme="secondary"
            onClick={onBack}
            icon="chevron_left_narrow"
            iconSize={2}
          >
            Eigen foto
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            icon="chevron_right_narrow"
            iconSize={2}
            iconPosition="right"
          >
            Afronden
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
}
