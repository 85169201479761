import { Card } from '@/components/Card';
import { Flex } from '@/components/Grid';
import { Input } from '@/components/Input';
import { Link } from '@/components/Link';
import { LocationItemType, getLocations } from '@/resource/location';
import { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Icon } from '../Icon';

const pattern = /^\d{4}$/;

type Props = {
  location: LocationItemType | null;
  postalCode: string;
  setLocation: (value: LocationItemType | null) => void;
  setPostalCode: (value: string) => void;
};

export function PostalCodeField({
  location,
  postalCode,
  setLocation,
  setPostalCode,
}: Props) {
  const [locations, setLocations] = useState<LocationItemType[]>([]);
  const activeLocations = locations.filter(
    (location) => location.province?.data.active
  );
  const inactiveProvince = locations
    .filter((location) => !location.province?.data.active)
    .map((location) => location.province?.data)
    .find((x) => x?.name);

  useEffect(() => {
    if (activeLocations.length === 1) {
      setLocation(activeLocations[0]);
    }
  }, [activeLocations, setLocation]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (postalCode: string | number) => {
      return getLocations({ postalCode: Number(postalCode) });
    },
    onSuccess(data) {
      setLocations(data);
    },
    onError() {
      setLocations([]);
      setLocation(null);
    },
  });

  useEffect(() => {
    if (pattern.test(postalCode)) {
      mutate(postalCode);
    } else {
      setLocations([]);
      setLocation(null);
    }
  }, [postalCode, setLocation, mutate]);

  return (
    <Flex dir="column" gap={2}>
      <Input
        type="text"
        label="Postcode"
        id="postal_code"
        name="postal_code"
        value={postalCode}
        inputMode="numeric"
        minLength={4}
        maxLength={4}
        placeholder="1234"
        required
        size={10}
        autoComplete="postal-code"
        onChange={({ target: { value } }) => {
          if (value === '' || /^\d{1,4}$/.test(value)) {
            setPostalCode(value);
          }
        }}
        append={location ? <Icon icon="check" theme={3} size={2} /> : undefined}
      />

      {location?.province && (
        <span>
          {location.place}, {location.province.data.name}
        </span>
      )}

      {location == null &&
        locations.length === 0 &&
        pattern.test(postalCode) &&
        !isLoading && <span>We konden je plaats helaas niet vinden :(</span>}

      {!location && activeLocations.length > 1 && (
        <Card>
          <Flex dir="column">
            <h4>Selecteer je plaats</h4>
            <p>
              {activeLocations.map((location, index) => (
                <Fragment key={location.id}>
                  {index > 0 && ', '}
                  <Link
                    to={{ type: 'home' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setLocation(location);
                    }}
                  >
                    {location.place}
                  </Link>
                </Fragment>
              ))}
            </p>
          </Flex>
        </Card>
      )}

      {inactiveProvince != null && (
        <p>Jouw NH is niet beschikbaar in provincie {inactiveProvince.name}</p>
      )}
    </Flex>
  );
}
