import { ReactNode } from 'react';
import styles from './Form.module.scss';

type Props = {
  children: ReactNode;
} & React.HTMLAttributes<HTMLFormElement>;

export function Form({ children, ...attr }: Props) {
  return (
    <form {...attr} className={styles.Form}>
      {children}
    </form>
  );
}
