import { AvatarUpload } from '@/components/AvatarUpload';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Flex } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { useModal } from '@/hooks/useModal';
import { useObjectURL } from '@/hooks/useObjectURL';
import { SetState } from '@/util/types';

type Props = {
  isValid: boolean;
  image: Blob | null;
  setImage: SetState<Blob | null>;
  onBack: () => void;
  onNext: () => void;
};

export function Step3({ isValid, image, setImage, onBack, onNext }: Props) {
  const modal = useModal();
  const src = useObjectURL(image);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <Flex dir="column" gap={4}>
        <Heading align="center">Bijna klaar!</Heading>
        <p>
          Kies je profielfoto. Zorg dat je buren je herkennen en upload een
          echte foto van jezelf.
        </p>
        <AvatarUpload
          src={src}
          onChange={(file) => {
            modal.open(
              {
                type: 'image_cropper',
                props: {
                  title: 'Foto bewerken',
                  aspectRatio: 1,
                  file,
                  rounded: true,
                  allowRotation: true,
                  onCancel() {
                    modal.close();
                  },
                  onSave({ crop }) {
                    setImage(crop);
                    modal.close();
                  },
                },
              },
              () => {
                //
              }
            );
          }}
        />

        <Flex justify="space-between">
          <Button
            theme="secondary"
            onClick={onBack}
            icon="chevron_left_narrow"
            iconSize={2}
          >
            Contact
          </Button>

          <Button
            type="submit"
            icon="chevron_right_narrow"
            iconPosition="right"
            iconSize={2}
            disabled={!isValid}
          >
            {image ? 'Afronden' : 'Overslaan'}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
}
