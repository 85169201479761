import { CompleteProfile } from '@/components/Sso';
import { Section } from '@/components/Section';
import { getAccount, useAccount } from '@/resource/account';
import { isProfileComplete } from '@/resource/profile';
import { AuthType } from '@/resource/auth';
import { routeToPath } from '@/util/routeToPath';
import { NextSeo } from 'next-seo';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import { PageRefType } from '@/resource/settings';

type Props = {
  handleLogin: (value: { code: string }) => Promise<AuthType>;
  rulesPage: PageRefType | null;
};

export function SsoPage({ handleLogin, rulesPage }: Props) {
  const router = useRouter();
  const { profile } = useAccount();
  const params = useSearchParams();
  const code = params.get('code');
  const isComplete = isProfileComplete(profile);

  useEffect(() => {
    if (code) {
      handleLogin({ code })
        .then(() => getAccount().catch((err) => console.log(err)))
        .catch((err) => console.log(err));
    }
  }, [code, router, handleLogin]);

  useIsomorphicLayoutEffect(() => {
    if (isComplete) {
      router.push(routeToPath({ type: 'account' }));
    }
  }, [isComplete, router]);

  return (
    <>
      <NextSeo
        title={
          profile && !isComplete
            ? 'Voltooi profiel'
            : 'Een moment geduld a.u.b.'
        }
      />
      <Section>
        {profile && !isComplete ? (
          <CompleteProfile profile={profile} rulesPage={rulesPage} />
        ) : (
          <h2>Een moment geduld a.u.b.</h2>
        )}
      </Section>
    </>
  );
}
