import { cx } from '@/util/cx';
import { scrollIntoViewWithOffset } from '@/util/scrollIntoViewWithOffset';
import { ReactNode, useRef, useEffect } from 'react';
import styles from './Wizard.module.scss';
import { Icon } from '@/components/Icon';
import { useUpdateEffect } from 'usehooks-ts';

type ViewType = {
  title: string;
  index: number;
  isValid: () => boolean;
  render: (props: { isActive: boolean; isValid: boolean }) => ReactNode;
  isDisabled: (currentIndex: number) => boolean;
};

type Props = {
  views: ViewType[];
  setIndex: (index: number) => void;
  index: number;
};

export function Wizard({ views, setIndex, index }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  // Scroll back up on mobile devices.
  useUpdateEffect(() => {
    if (ref.current && window.innerWidth <= 1024) {
      scrollIntoViewWithOffset(ref.current, -24);
    }
  }, [index]);

  return (
    <>
      <div className={styles.Wizard} ref={ref}>
        {views.map((view, i) => {
          const isActive = i === index;
          const previousViews = views.slice(0, i);
          const isValid =
            view.isValid() &&
            (i > 0 ? previousViews.every((view) => view.isValid()) : true);
          const isDone = isValid && index > i;

          return (
            <button
              key={view.index}
              title={view.title}
              type="button"
              className={cx(styles.Tab, isDone && styles['is-done'])}
              disabled={!isValid || view.isDisabled(index)}
              aria-pressed={isActive ? 'true' : 'false'}
              onClick={() => setIndex(i)}
            >
              {isDone ? <Icon icon="check" theme={1} size={2} /> : i + 1}
            </button>
          );
        })}
      </div>

      {views.map((view, i) => (
        <View key={view.index} view={view} active={i === index} />
      ))}
    </>
  );
}

function View({ view, active }: { view: ViewType; active: boolean }) {
  const ref = useRef<HTMLDivElement>(null);

  // Put focus on the activated view.
  useEffect(() => {
    if (ref.current && active && view.index > 0) {
      ref.current.focus();
    }
  }, [active, view.index]);

  return (
    <div
      ref={ref}
      key={view.index}
      className={styles.View}
      hidden={!active}
      tabIndex={active ? -1 : undefined}
    >
      {view.render({ isActive: active, isValid: view.isValid() })}
    </div>
  );
}
